import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Spinner from '../base/Spinner';
import requests from '../requests';
import {
  Col,
  Empty,
  Row,
  Typography,
  Card,
  Radio,
  Button,
  message,
  Input,
} from 'antd';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import translator from '../translator';
import axios from 'axios';
import FastRegistration from '../forms/FastRegistration';

const { Title, Paragraph, Text } = Typography;
const Poster = styled.div`
  background: url('${(props) => props.src}') no-repeat center;
  background-size: cover;
  width: 250px;
  height: 250px;
  max-width: 100%;
`;

class GuestPayment extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      id: props.match.params.id,
      data: null,
      errorMessage: false,
      methods: [],
      activeTab: 'cc',
      activeOption: null,
      buttonLoading: false,
      promoLoading: false,
      promoCode: null,
      xr: 1.5,
      iframe: null,
    };
    this.childFormRef = React.createRef();
  }

  async componentDidMount() {
    let { id } = this.state;
    let r;
    if (this.props.history.action !== 'PUSH') {
      r = await requests.post('/finance/init_guest_payment', {
        course: +id,
      });

      if (r.status !== 200) {
        window.parent.location.href = '/';
      }
      id = r.data.push.replace('/guest_payment/', '');
    }
    r = await requests.get(`/finance/guest_payment_info?id=${id}`);
    const xr = await axios.get(
      'https://min-api.cryptocompare.com/data/price?fsym=EUR&tsyms=CAD',
    );

    if (r.status === 200) {
      this.setState({
        id,
        data: r.data.payment,
        methods: r.data.methods,
        activeOption:
          r.data.methods[0].options[0]?.id || r.data.methods[0].options[1]?.id,
        xr: xr.data,
      });
    } else {
      this.setState({
        errorMessage: r.error,
      });
    }
    this.setState({
      loading: false,
    });
  }

  getTabs = () => {
    const { methods } = this.state;
    return methods.map((v) => {
      return {
        key: v.key,
        tab: v.name,
      };
    });
  };

  getTabContent = () => {
    const { methods, activeTab } = this.state;
    const contents = {};
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    methods.map((v) => {
      let content;
      if (v.options.length > 0) {
        content = v.options.map((val, index) => {
          return (
            <Radio key={index} style={radioStyle} value={val.id}>
              {val.name}
            </Radio>
          );
        });
      } else {
        content = (
          <div style={{ textAlign: 'center' }}>
            <Empty description={'No options found'} />
          </div>
        );
      }

      Object.assign(contents, {
        [v.key]: content,
      });
      return null;
    });
    return contents[activeTab];
  };

  pay = async () => {
    const { activeOption, id } = this.state;
    this.setState({
      buttonLoading: true,
    });
    const r = await requests.post('/finance/payment_page', {
      option: activeOption,
      payment: id,
    });
    if (r.status === 200) {
      window.parent.location.href = r.data.redirect;
      // this.setState({ iframe: r.data.redirect });
    } else {
      message.error(r.error);
    }
    this.setState({
      buttonLoading: false,
    });
  };

  applyPromoCode = async () => {
    const { promoCode, id } = this.state;
    this.setState({
      promoLoading: true,
    });
    const r = await requests.post('/finance/apply_promo_code', {
      value: promoCode,
      payment: id,
    });
    if (r.status === 200) {
      this.setState({
        data: r.data,
      });
    } else {
      message.error(r.error);
    }
    this.setState({
      promoLoading: false,
    });
  };

  render() {
    const {
      loading,
      errorMessage,
      data,
      activeTab,
      activeOption,
      methods,
      buttonLoading,
      promoLoading,
      promoCode,
      xr,
      iframe,
    } = this.state;
    const { lang, brand, authorize } = this.props;
    const selectedOption = methods[0]?.options.filter(
      (option) => option.id === activeOption,
    )[0];

    if (loading) return <Spinner />;
    if (errorMessage)
      return (
        <Empty
          style={{ maxWidth: 300, margin: '0 auto' }}
          description={errorMessage}
        />
      );
    return (
      <Row gutter={16}>
        <Col md={12}>
          <Title level={3}>{translator[lang].buyCourse}</Title>
          <Card title={data.course.title}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Poster src={data.course.poster} />
              </Col>
              <Col xs={24} md={16}>
                <Paragraph>{data.course.short_description}</Paragraph>
                {/*<ReactPlayer style={{marginBottom: 20}} width={'100%'} height={'auto'} url={data.course.intro_video.video_url} controls/>*/}
                <Paragraph>{data.course.description}</Paragraph>
                <Paragraph>{data.course.extra_description}</Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12}>
          <Title level={3}>{translator[lang].chooseMethod}</Title>
          <Card
            tabList={this.getTabs()}
            onTabChange={(key) => {
              this.setState({
                activeTab: key,
              });
              methods.map((v) => {
                if (v.key === key) {
                  if (v.options.length === 0) {
                    this.setState({
                      activeOption: null,
                    });
                  } else {
                    this.setState({
                      activeOption: v.options[0].id,
                    });
                  }
                }
                return null;
              });
            }}
            activeTabKey={activeTab}
          >
            {data.amount > 0 && (
              <Radio.Group
                onChange={(e) =>
                  this.setState({
                    activeOption: e.target.value,
                  })
                }
                value={activeOption}
                style={{ width: '100%', marginBottom: 10 }}
              >
                {this.getTabContent()}
              </Radio.Group>
            )}
            {selectedOption?.name === 'Pay with Interac®' && (
              <div className="popup" style={{ color: 'red', marginBottom: 10 }}>
                <small>{translator[lang].canadaOnly}</small>
              </div>
            )}
            {!data.discount && (
              <Input
                defaultValue={promoCode}
                onChange={(e) =>
                  this.setState({
                    promoCode: e.target.value,
                  })
                }
                style={{ width: '100%', maxWidth: 300, marginBottom: 20 }}
                placeholder={'Apply promo code'}
                addonAfter={
                  <Button
                    onClick={this.applyPromoCode}
                    loading={promoLoading}
                    type={'link'}
                  >
                    Apply
                  </Button>
                }
              />
            )}
            <FastRegistration
              formRef={this.childFormRef}
              lang={lang}
              brand={brand}
              pay={() => this.pay()}
              authorize={authorize}
            />
            {iframe ? (
              <iframe src={iframe} width={900} height={700}></iframe>
            ) : (
              <Button
                danger
                disabled={!activeOption}
                onClick={() => this.childFormRef.current.submit()}
                type={'primary'}
                shape={'round'}
                loading={buttonLoading}
                block
              >
                {data.amount > 0
                  ? selectedOption?.name === 'Pay with Interac®'
                    ? `${translator[lang].pay} ${data.amount * xr.CAD} CAD`
                    : `${translator[lang].pay} ${data.amount} ${data.currency}`
                  : translator[lang].free}
              </Button>
            )}
          </Card>
          <small>{translator[lang].disclaimer}</small>
        </Col>
      </Row>
    );
  }
}

export default withRouter(GuestPayment);
