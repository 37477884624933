import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Typography,
  Select,
  InputNumber,
} from 'antd';
import requests from '../requests';
import User from '../base/User';
import Spinner from '../base/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';
import translator from '../translator';

const { Paragraph, Title } = Typography;
const { Option } = Select;

class Registration extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      buttonLoad: false,
      phoneCode: null,
      loading: true,
      captcha: true,
    };
  }

  register = async (values) => {
    // const { captcha } = this.state;
    const { source, lang, pay } = this.props;
    // if (!captcha) {
    //   message.error(translator[lang].notRobot);
    //   return null;
    // }
    // const { authorize } = this.props;
    this.setState({
      buttonLoad: true,
    });
    delete values.tc;
    delete values.pp;
    values.source = source;
    values.lang = lang;
    // values.captcha = captcha;
    const res = await requests.post('/authorization/guest_register', values);
    if (res.status === 200) {
      // Modal.info({
      //   title: '',
      //   content: (
      //     <Paragraph dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      //       {translator[lang].disclaimer}
      //     </Paragraph>
      //   ),
      //   onOk() {
      const user = new User();
      user.authorize(res.data.data);
      // authorize();
      pay();
      localStorage.clear();
      //   },
      // });
      // message.success(res.data.message);
    } else {
      message.error(res.error);
      this.setState({
        buttonLoad: false,
      });
    }
  };

  async componentDidMount() {
    const res = await requests.get('/utils/country_code');
    // let res2 = await requests.get('/utils/country_list');
    if (res.status === 200) {
      this.setState({
        phoneCode: res.data.dial_code,
        // countryList: res2.data,
        loading: false,
      });
    }
  }

  handleCaptcha = (value) => {
    this.setState({
      captcha: value,
    });
  };

  render() {
    const { switchForm, brand, lang, formRef } = this.props;
    const { buttonLoad, phoneCode, loading } = this.state;
    if (loading) return <Spinner />;
    return (
      <div>
        <Title level={3}>{translator[lang].hello}</Title>
        <Title level={4}>{translator[lang].applyForAccount}</Title>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{ phone: phoneCode }}
          onFinish={this.register}
        >
          <Form.Item
            name="first_name"
            rules={[
              { required: true, message: translator[lang].pleaseFirstName },
            ]}
          >
            <Input placeholder={translator[lang].firstName} />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              { required: true, message: translator[lang].pleaseLastName },
            ]}
          >
            <Input placeholder={translator[lang].lastName} />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: translator[lang].pleaseEmail }]}
          >
            <Input placeholder={translator[lang].email} />
          </Form.Item>

          <Form.Item
            name={'phone'}
            // label={translator[lang].phone}
            rules={[{ required: true }]}
            // extra={
            // codeSent
            //   ? `Resend in ${secondsToGo} seconds`
            //   : profileData.phone_confirmed
            //   ? translator[lang].phoneConfirmed
            //   : phoneConfirmationText
            // }
          >
            <InputNumber
              formatter={(value) => `+ ${value}`}
              // parser={value => value.replace('+', '')}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="address"
            rules={[
              { required: true, message: translator[lang].pleaseAddress },
            ]}
          >
            <Input placeholder={translator[lang].address} />
          </Form.Item>

          {/* <Form.Item
            name="city"
            rules={[{ required: true, message: translator[lang].pleaseCity }]}
          >
            <Input placeholder={translator[lang].city} />
          </Form.Item>

          <Form.Item
            name={'country'}
            label={translator[lang].country}
            rules={[{ required: true }]}
          >
            <Select showSearch placeholder={translator[lang].selectCountry}>
              {countryList.map((v, i) => {
                return (
                  <Option key={i} value={v.iso}>
                    {v.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="zip"
            rules={[{ required: true, message: translator[lang].pleaseZip }]}
          >
            <Input placeholder={translator[lang].zip} />
          </Form.Item>

          <Form.Item
            name="state"
            rules={[{ required: true, message: translator[lang].pleaseState }]}
          >
            <Input placeholder={translator[lang].state} />
          </Form.Item> */}

          <Form.Item
            name="password"
            rules={[
              { required: true, message: translator[lang].pleasePassword },
            ]}
          >
            <Input.Password placeholder={translator[lang].password} />
          </Form.Item>

          <Form.Item name="promo_code">
            <Input placeholder={translator[lang].promoCode} />
          </Form.Item>

          <Form.Item
            name="tc"
            valuePropName="checked"
            rules={[{ required: true, message: translator[lang].pleaseTerms }]}
            style={{ marginBottom: 0 }}
          >
            <Checkbox>
              {translator[lang].iAccept}{' '}
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={brand.tc_link}
              >
                {translator[lang].tAndC}
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="pp"
            valuePropName="checked"
            rules={[{ required: true, message: translator[lang].pleasePolicy }]}
          >
            <Checkbox>
              {translator[lang].iAccept}{' '}
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={brand.pp_link}
              >
                {translator[lang].pp}
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            {/* <ReCAPTCHA
                sitekey={brand.google_captcha_key}
                onChange={this.handleCaptcha}
                size="normal"
                className="captcha"
                badge={'inline'}
            /> */}
          </Form.Item>
          {/* <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={buttonLoad}
              danger
              shape={'round'}
            >
              {translator[lang].registerNow}
            </Button>
          </Form.Item> */}
        </Form>
        <div className="form-links" style={{ textAlign: 'right' }}>
          <Paragraph>
            {translator[lang].alreadyHave}{' '}
            <button onClick={() => switchForm('login')} type="link">
              {translator[lang].logIn}
            </button>
          </Paragraph>
        </div>
      </div>
    );
  }
}

export default Registration;
