import React, {Component} from 'react';
import {Button, Form, Input, Typography, message, Modal, Row, Col} from 'antd'
import PasswordRecovery from '../modals/PasswordRecovery'
import requests from "../requests";
import User from "../base/User";
import ReCAPTCHA from "react-google-recaptcha";
import translator from "../translator";


const {Paragraph, Title} = Typography;

class Login extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      showRecoveryModal: false,
      buttonLoad: false,
      captcha: false
    }
  }

  logIn = async (values) => {
    const {captcha} = this.state;
    const {lang} = this.props;
    if (!captcha) {
      message.error(translator[lang].notRobot);
      return null;
    }
    this.setState({
      buttonLoad: true
    });
    const {authorize} = this.props;
    const res = await requests.post('/authorization/login', values);
    if (res.status === 200) {
      Modal.info({
        title: '',
        content: (
            <Paragraph dir={lang === 'ar' ? 'rtl' : 'ltr'}>{translator[lang].disclaimer}</Paragraph>
        ),
        onOk(){
          const user = new User();
          user.authorize(res.data);
          authorize();
        }
      })
    } else {
      message.error(res.error);
      this.setState({
        buttonLoad: false
      });
    }
  };

  handleCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  }

  render(){
    const {switchForm, brand, lang} = this.props;
    const {showRecoveryModal, buttonLoad} = this.state;
    return (
      <div>
        <Title level={4}>{translator[lang].pleaseLogIn}:</Title>
        <Form
          name="basic"
          onFinish={this.logIn}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: translator[lang].pleaseEmail }]}
          >
            <Input placeholder={translator[lang].email} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: translator[lang].pleasePassword }]}
          >
            <Input.Password placeholder={translator[lang].password} />
          </Form.Item>
          <Form.Item style={{textAlign: 'center'}}>
            <ReCAPTCHA
                sitekey={brand.google_captcha_key}
                onChange={this.handleCaptcha}
                size="normal"
                className="captcha"
                badge={'inline'}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={buttonLoad} danger shape={'round'}>
              {translator[lang].logIn}
            </Button>
          </Form.Item>
        </Form>
        <Row className={'form-links'}>
          <Col xs={12}>
            <Paragraph>
              <button onClick={() => {
                this.setState({
                  showRecoveryModal: true
                })
              }} type="link">{translator[lang].forgotPassword}</button>
            </Paragraph>
          </Col>
          <Col xs={12}>
            <Paragraph>{translator[lang].noAccount} <button onClick={() => switchForm('registration')} type="link">{translator[lang].signUp}</button></Paragraph>
          </Col>
        </Row>
        <PasswordRecovery show={showRecoveryModal} close={() => this.setState({
          showRecoveryModal: false
        })}/>
      </div>

    )
  }

}

export default Login;

