import React, { Component } from 'react';
import requests from '../requests';
import Spinner from '../base/Spinner';
import { Button, Empty, message, Layout } from 'antd';
import styled from 'styled-components';
import SingleCourse from './SingleCourse';
import PurchaseAgreementsModal from '../modals/PurchaseAgreements';
import { withRouter } from 'react-router-dom';
import translator from '../translator';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, calc(16% - 15px));
  grid-gap: 15px;

  .ant-card {
    margin-bottom: 40px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 100%);
    .ant-card {
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }
    .ant-card-cover {
      width: 250px;
      margin: auto;
    }
  }
`;

class Courses extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      list: [],
      path: props.path,
    };
  }

  async componentDidMount() {
    const { path } = this.state;
    const { lang } = this.props;
    const r = await requests.get(`/courses/${path}`);
    if (r.status === 200) {
      this.setState({
        list: r.data,
        loading: false,
        new_purchases: r.data.filter(
          (course) => course.purchase_agree === false,
        ),
      });
    } else {
      message.error(translator[lang].unexpectedError);
    }
  }

  agree_purchase = (id) => {
    this.setState(({ new_purchases }) => ({
      new_purchases: new_purchases.filter(
        (course) => course.purchase_id !== id,
      ),
    }));
  };

  render() {
    const { loading, list, new_purchases } = this.state;
    const { lang, user, brand } = this.props;
    if (loading) return <Spinner />;
    return (
      <div>
        {user &&
          new_purchases.map((purchase) => (
            <PurchaseAgreementsModal
              key={purchase.purchase_id}
              user={user}
              brand={brand}
              purchase={purchase}
              agree={this.agree_purchase}
            />
          ))}
        {list.length > 0 ? (
          <Layout.Content style={{ padding: '12px' }}>
            <Wrapper>
              {list.map((v, i) => {
                return (
                  <SingleCourse data={v} key={i} lang={lang} guest={!user} />
                );
              })}
            </Wrapper>
          </Layout.Content>
        ) : (
          <Empty description={translator[lang].noCourses}>
            <Button
              shape={'round'}
              type={'primary'}
              onClick={() => this.props.history.push('/buy')}
            >
              {translator[lang].buyNow}
            </Button>
          </Empty>
        )}
      </div>
    );
  }
}

export default withRouter(Courses);
